body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    font-size: 14px;
}

ul{
    padding: 0;
    margin: 0;
    list-style-type: none;
}

li{
    margin: 0.3em 0;
    clear: both;
}

h3 {
    margin: 0;
    padding: 0;
}

.placeholder{
    font-style: italic;
    opacity: 0.6;
}

.placeholder:hover{
    font-style: italic;
    opacity: 1;
}

#categories h3 {
    font-size: 20px;
}

.category{
    display: block;
    border-left: 3px solid;
    padding: 0 0.5em;
    margin: 0.8em;
}

.select{
    float: right;
}

.info{
    color: black;
    font-size: 12px;
    font-weight: normal;
}

#game{
    display: grid;
    grid-template-columns: 26em auto;
    grid-template-rows: auto auto;
    grid-template-areas: "players actions" "categories actions";
}

@media screen and (max-width: 940px){
    #game{
        grid-template-columns: auto;
        grid-template-rows: auto auto auto;
        grid-template-areas: "players" "categories" "actions";
    }

    #actions{
        margin: 1.5em;
    }

    #actions h3{
        margin-top: 0;
    }
}

#players{
    grid-area: players;
    margin: 0 1.5em;
}

.turn{
    text-decoration: underline;
}

#categories{
    grid-area: categories;
}

#actions{
    grid-area: actions;
    margin-right: 1.5em;
}

#log{
    width: 100%;
}

#log li{
    padding: 0.4em 0;
    border-bottom: 1px solid darkgray;
}

#log .help{
    font-style: italic;
    color: darkgray;
}

#log th{
    text-align: left;
}

#log td, #log th{
    padding-right: 1em;
    height: 1.4em;
}

.count:after {
    content: ", ";
}

.count:last-child:after{
    content: "";
}